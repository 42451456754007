<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Portfolio</span>
          <h3>Creative Portfolio</h3>
        </div>
        <!-- End Left -->
        <div class="portfolio_filter">
          <ul>
            <li>
              <a
                v-on:click="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
                >All</a
              >
            </li>
            <!-- End All for tabcontent 1 -->
            <li>
              <a
                v-on:click="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
                >Apps</a
              >
            </li>
            <!-- End Vimeo tabcontent 2 -->
            <li>
              <a
                v-on:click="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
                >Café</a
              >
            </li>
            <!-- End .Youtube tabcontent 3 -->
            <li>
              <a
                v-on:click="activetab = 4"
                :class="[activetab === 4 ? 'active' : '']"
                >Gallery</a
              >
            </li>
            <!-- End Mix tabcontent 4 -->
            <li>
              <a
                v-on:click="activetab = 5"
                :class="[activetab === 5 ? 'active' : '']"
                >Other</a
              >
            </li>
            <!-- End Gallery tabcontent 5 -->
          </ul>
        </div>
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <div v-if="activetab === 1" class="tabcontent">
      <div v-if="allItems.length == 0" class="about_image">
        <img src="../assets/img/other/coming_soon.png" alt="about" />
      </div>
      <ul v-else class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in allItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <div v-if="appsItems.length == 0" class="about_image">
        <img src="../assets/img/other/coming_soon.png" alt="about" />
      </div>
      <ul v-else class="portfolio_list">
        <CoolLightBox :items="appsItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in appsItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <div v-if="cafeItems.length == 0" class="about_image">
        <img src="../assets/img/other/coming_soon.png" alt="about" />
      </div>
      <ul v-else class="portfolio_list">
        <CoolLightBox :items="cafeItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in cafeItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 4" class="tabcontent">
      <div v-if="galleryItems.length == 0" class="about_image">
        <img src="../assets/img/other/coming_soon.png" alt="about" />
      </div>
      <ul v-else class="portfolio_list">
        <CoolLightBox
          :items="galleryItems"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in galleryItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 4 -->

    <div v-if="activetab === 5" class="tabcontent">
      <div v-if="otherItems.length == 0" class="about_image">
        <img src="../assets/img/other/coming_soon.png" alt="about" />
      </div>
      <ul v-else class="portfolio_list">
        <CoolLightBox :items="otherItems" :index="index" @close="index = null">
        </CoolLightBox>

        <li
          v-for="(image, imageIndex) in otherItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 5 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: require("../assets/img/portfolio/_cafe/1.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/1.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/2.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/2.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/3.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/3.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/4.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/4.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/5.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/5.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/6.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/6.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/7.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/7.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_gallery/1.jpg"),
          thumb: require("../assets/img/portfolio/_gallery/1.jpg"),
        },
      ],
      appsItems: [],
      cafeItems: [
        {
          src: require("../assets/img/portfolio/_cafe/1.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/1.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/2.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/2.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/3.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/3.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/4.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/4.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/5.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/5.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/6.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/6.jpg"),
        },
        {
          src: require("../assets/img/portfolio/_cafe/7.jpg"),
          thumb: require("../assets/img/portfolio/_cafe/7.jpg"),
        },
      ],
      galleryItems: [
        // {
        //   src: "https://vimeo.com/43338103",
        //   thumb: require("../assets/img/portfolio/5.jpg"),
        // },
        {
          src: require("../assets/img/portfolio/_gallery/1.jpg"),
          thumb: require("../assets/img/portfolio/_gallery/1.jpg"),
        },
      ],
      otherItems: [],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
